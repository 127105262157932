<template>
  <div class="compare-relatives-table-person">
    <div class="row text-md text-md-mobile" v-for="item in items">
      <div class="label">{{ item.label }}:</div>
      <div class="value">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
import {formatApproximateDate, getFormattedFactText, getFormattedResidenceText} from '@common/utils/utils';

export default {
  props: {
    person: Object,
  },
  computed: {
    items() {
      return [
        {label: 'First Names', value: this.person.first_names.map(name => name.value).join(', ')},
        {label: 'Surnames', value: this.person.surnames.map(name => name.value).join(', ')},
        {label: 'Birth Place', value: this.person.birth_location && this.person.birth_location.display_text},
        {label: 'Death Place', value: this.person.death_location && this.person.death_location.display_text},
        {label: 'Gender', value: this.person.gender_display || 'Unknown'},
        {label: 'Birth Date', value: this.person.birth_date && formatApproximateDate(this.person.birth_date)},
        {label: 'Death Date', value: this.person.death_date && formatApproximateDate(this.person.death_date)},
        {
          label: 'Residence',
          value: this.person.residence_location.map(residence => getFormattedResidenceText(residence)).join(', '),
        },
        ...this.person.facts.map(fact => {
          return {label: fact.fact_type.label, value: getFormattedFactText(fact)};
        }),
      ].filter(item => item.value);
    },
  },
  name: 'CompareRelativesTablePerson',
};
</script>

<style lang="scss" scoped>
.compare-relatives-table-person {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .row {
    display: flex;
    align-items: flex-start;

    .label {
      color: $neutral-500;
      min-width: 200px;
      max-width: 200px;
      margin-right: 5px;
    }
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    .row {
      .label {
        min-width: 110px;
        max-width: 110px;
      }
    }
  }
}
</style>
