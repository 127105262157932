<template>
  <div class="person-link-tag text-sm">
    <account-icon :size="20"></account-icon>
    <router-link :to="profileRoute" class="bold">{{ personFullName }}</router-link>
    <span v-if="personDates" class="person-dates">{{ personDates }}</span>
  </div>
</template>

<script>
import {getLifetimeYears} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import AccountIcon from 'vue-material-design-icons/Account';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    treeId: [String, Number],
    person: Object,
  },
  computed: {
    personId() {
      return this.person.object_id;
    },
    profileRoute() {
      const params = {id: this.treeId, personId: this.personId};
      const query = {tab: 'hints'};
      return {name: 'familytree-profile-details', params, query};
    },
    personDates() {
      const years = getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
      return years ? `(${years})` : '';
    },
    personFullName() {
      const firstNames = this.person.first_names || [];
      const surnames = this.person.surnames || [];
      return getFullNameEn(firstNames, surnames) || getFullNameCn(firstNames, surnames) || UNKNOWN_NAME;
    },
  },
  components: {AccountIcon},
  name: 'PersonLinkTag',
};
</script>

<style lang="scss" scoped>
.person-link-tag {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  border: 1px solid $neutral-200;
  border-radius: 4px;
  background: white;

  .account-icon,
  .person-dates {
    color: $neutral-500;
  }
}
</style>
