<template>
  <div class="table-cell">
    <div v-if="isList && !commaSeparated">
      <div v-for="valueItem in value" class="value">
        <div>{{ getDisplayValue(valueItem) }}</div>

        <div class="input-warning" v-if="!isSaveMode && valueItem && valueItem.showIdWarning">
          <alert-icon :size="16" /><span>Not a linked place.</span
          ><mcr-wiki-glossary-link naturalId="linked-places" text="Learn more" />
        </div>
        <div class="meta-info text-sm" v-if="!isSaveMode && valueItem.metaInfo">
          <div v-for="metaInfoItem in valueItem.metaInfo">{{ metaInfoItem }}</div>
        </div>
      </div>
      <div v-if="isSelected" class="new-items">
        <div v-for="valueItem in newValue" class="new-item">{{ getDisplayValue(valueItem) }}</div>
      </div>
    </div>
    <div v-else-if="isList && commaSeparated" class="inline-list">
      <div v-for="valueItem in value">{{ getDisplayValue(valueItem) }}</div>
      <template v-if="isSelected">
        <div v-for="valueItem in newValue" class="new-item">{{ getDisplayValue(valueItem) }}</div>
      </template>
    </div>
    <div v-else>
      <div v-if="getSingleDisplayValue(value)" class="value" :class="{'is-crossed': isSelected}">
        {{ getSingleDisplayValue(value) }}
      </div>
      <div class="input-warning" v-if="!isSaveMode && value && value.showIdWarning">
        <alert-icon :size="16" /><span>Not a linked place.</span
        ><mcr-wiki-glossary-link naturalId="linked-places" text="Learn more" />
      </div>
      <div v-if="isSelected" class="new-items new-item">{{ getSingleDisplayValue(newValue) }}</div>
    </div>
  </div>
</template>

<script>
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import AlertIcon from 'vue-material-design-icons/AlertOutline';

export default {
  props: {
    value: [String, Array, Object],
    newValue: [String, Array, Object],
    isSelected: {type: Boolean, optional: true},
    commaSeparated: {type: Boolean, optional: true},
    isList: Boolean,
    displayMethod: Function,
    isSaveMode: Boolean,
  },
  methods: {
    getDisplayValue(value) {
      if (this.displayMethod) {
        return this.displayMethod(value);
      }
      return value;
    },
    getSingleDisplayValue(value) {
      if (this.isList) {
        return this.value.map(item => this.getDisplayValue(item)).join();
      }
      return this.getDisplayValue(value);
    },
  },
  components: {McrWikiGlossaryLink, AlertIcon},
  name: 'ComparePersonsTableCell',
};
</script>

<style scoped lang="scss">
.table-cell {
  .inline-list {
    div {
      display: inline-block;
      white-space: pre-wrap;
      &:not(:last-child)::after {
        content: ', ';
      }
    }
  }
  .value + .new-items {
    margin-top: 4px;
  }
  .new-item {
    color: $success-color;
    font-weight: bold;
  }
  .is-crossed {
    color: $neutral-500;
    text-decoration: line-through;
  }

  &.multiline {
    white-space: pre-line;
    .value + .value {
      margin-top: 4px;
    }
  }

  .input-warning {
    margin: 5px 0;
  }

  .meta-info {
    color: $neutral-400;
  }
}
</style>
