<template>
  <div class="preview-unavailable">
    <div class="access-notice">This image is hidden because it may contain private information of a living person.</div>
  </div>
</template>

<script>
export default {
  name: 'PreviewUnavailableLivingPerson',
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/viewer/styles/preview-unavailable.scss';
</style>
