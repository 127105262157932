import consts from '@common/utils/consts';
import {formatApproximateDate, getDateLocation, getFormattedResidenceText, isChineseText} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {sortBy} from 'lodash';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';

import baseConsts from '@/base/utils/consts';

export function getRecordTitle(fullNameEn, fullNameCn, source) {
  const personName = fullNameEn || fullNameCn;
  const title = `"${personName}" in ${source.title_en || ''} ${source.title_ch || ''}`.trim();
  const year = source.publication_year
    ? source.publication_year
    : source.time_period_start && source.time_period_end
    ? `${source.time_period_start}-${source.time_period_end}`
    : '';
  const yearStr = year ? `, ${year}` : '';
  return `${title}${yearStr}`;
}

function getNameDisplay(name) {
  return name.value;
}
function getPlaceDisplay(place) {
  return place && place.display_text;
}
export function getRelativeNamesDisplay(personData) {
  const fullNameEn = getFullNameEn(personData.first_names, personData.surnames);
  const fullNameCn = getFullNameCn(personData.first_names, personData.surnames);
  const fullName = `${fullNameEn} ${fullNameCn}`.trim();
  const nameLower = fullName.toLowerCase();
  let aka = personData.matched_names ? personData.matched_names.filter(alias => !nameLower.includes(alias)) : [];
  if (aka.length) {
    const akaStr = aka.map(alias => `"${capitalize(alias)}"`).join(', ');
    return `${fullName} (a.k.a. ${akaStr})`;
  }
  return fullName;
}

function getRelativeMetaInfo(personData) {
  const coupleMeta = personData.couple_meta;
  let meta = [];
  if (coupleMeta) {
    const marriedDatePlace = getDateLocation(
      coupleMeta.marriage_date,
      coupleMeta.marriage_place ? coupleMeta.marriage_place.display_text : ''
    );
    const divorcedDatePlace = getDateLocation(
      coupleMeta.divorce_date,
      coupleMeta.divorce_place ? coupleMeta.divorce_place.display_text : ''
    );
    const married = marriedDatePlace ? `Married: ${marriedDatePlace}` : '';
    const divorced = divorcedDatePlace ? `Divorced: ${divorcedDatePlace}` : coupleMeta.is_divorced ? 'Divorced' : '';
    if (married) {
      meta.push(married);
    }
    if (divorced) {
      meta.push(divorced);
    }
  }
  return meta;
}

function getDifferentNames(personValue, matchValue) {
  return matchValue.filter(n => !personValue.find(p => p.value.toLowerCase().trim() === n.value.toLowerCase().trim()));
}
function compareStrings(personValue, matchValue) {
  return matchValue && personValue !== matchValue;
}
export function compareDates(personValue, matchValue) {
  if (isEmpty(matchValue) || !matchValue.year) {
    return false;
  }
  if (personValue.approximation_years || matchValue.approximation_years || personValue.year !== matchValue.year) {
    return true;
  }
  if (matchValue.month && personValue.month !== matchValue.month) {
    return true;
  }
  return Boolean(personValue.month === matchValue.month && matchValue.day && personValue.day !== matchValue.day);
}
export function comparePlaces(personValue, matchValue) {
  if (isEmpty(matchValue)) {
    return false;
  }
  if (isEmpty(personValue)) {
    return true;
  }
  if (personValue.place_id && matchValue.place_id && personValue.place_id !== matchValue.place_id) {
    const placeParents = personValue.place_parents || [];
    return !placeParents.find(place => place.id === matchValue.place_id);
  }
  return matchValue.display_text !== personValue.display_text;
}

const fieldsConfig = [
  {
    label: 'First Name',
    fieldName: 'first_names_en',
    displayMethod: getNameDisplay,
    commaSeparated: true,
    isList: true,
    newValuesMethod: getDifferentNames,
  },
  {
    label: 'Last Name',
    fieldName: 'surnames_en',
    displayMethod: getNameDisplay,
    commaSeparated: true,
    isList: true,
    newValuesMethod: getDifferentNames,
  },
  {
    label: 'First Name (CN)',
    fieldName: 'first_names_cn',
    displayMethod: getNameDisplay,
    commaSeparated: true,
    isList: true,
    newValuesMethod: getDifferentNames,
  },
  {
    label: 'Last Name (CN)',
    fieldName: 'surnames_cn',
    displayMethod: getNameDisplay,
    commaSeparated: true,
    isList: true,
    newValuesMethod: getDifferentNames,
  },
  {
    label: 'Other Name',
    fieldName: 'other_names',
    displayMethod: getNameDisplay,
    commaSeparated: true,
    isList: true,
    default: [],
    canSave: false,
  },
  {label: 'Gender', fieldName: 'gender', displayMethod: value => consts.GENDERS[value], compareMethod: compareStrings},
  {
    label: 'Birth Date',
    fieldName: 'birth_date',
    displayMethod: formatApproximateDate,
    default: {},
    compareMethod: compareDates,
  },
  {label: 'Birth Place', fieldName: 'birth_location', displayMethod: getPlaceDisplay, compareMethod: comparePlaces},
  {
    label: 'Death Date',
    fieldName: 'death_date',
    displayMethod: formatApproximateDate,
    default: {},
    compareMethod: compareDates,
  },
  {label: 'Death Place', fieldName: 'death_location', displayMethod: getPlaceDisplay, compareMethod: comparePlaces},
  {
    label: 'Residence',
    fieldName: 'residence_location',
    isList: true,
    displayMethod: getFormattedResidenceText,
    newValuesMethod: (personValue, matchValue) => {
      return matchValue.filter(
        n => !personValue.find(p => getFormattedResidenceText(p) === getFormattedResidenceText(n))
      );
    },
  },
];

// todo: remove when the business info fields are refactored
export function getOtherFieldsConfig(otherFields) {
  return otherFields
    .filter(field => field.value && (typeof field.value === 'string' || field.value.name))
    .map(field => {
      return {
        label: field.label,
        fieldName: `other_field_${field.label}`,
        displayMethod: value => {
          return typeof value === 'string' ? value : value.name;
        },
        personValue: '',
        matchValue: field.value,
        isList: false,
        canSave: false,
      };
    });
}

export function getFactsConfig(personFacts, matchFacts, mentionId) {
  if (!personFacts || !matchFacts) {
    return [];
  }
  let fields = [];
  const factsGrouped = {};
  matchFacts.forEach(fact => {
    const key = fact.fact_type.id;
    factsGrouped[key] = factsGrouped[key] || [];
    factsGrouped[key].push(fact);
  });
  const displayMethod = fact => {
    const {value, start_date, end_date, place_display_text, cemetery, info} = fact;
    return [
      value,
      start_date ? formatApproximateDate(start_date) : '',
      end_date ? formatApproximateDate(end_date) : '',
      place_display_text,
      cemetery ? cemetery.name : '',
      ...info.map(infoItem => `${infoItem.label}: ${infoItem.value}`),
    ]
      .filter(text => !!text)
      .join('\n');
  };
  const newValuesMethod = (personValue, matchValue) => {
    return matchValue.filter(n => !personValue.find(p => displayMethod(p) === displayMethod(n)));
  };
  const modifyForSaveMethod = item => {
    const info = {};
    if (item.info && item.info.length) {
      item.info.forEach(infoItem => {
        info[infoItem.key] = infoItem.value;
      });
    }
    return {
      fact_type_id: item.fact_type.id,
      mention_id: mentionId,
      value: item.value,
      cemetery_id: item.cemetery ? item.cemetery.id : null,
      place_display_text: item.place_display_text,
      place_id: item.place_id,
      start_date: item.start_date,
      end_date: item.end_date,
      info: info,
    };
  };
  Object.keys(factsGrouped).forEach(key => {
    const configPersonValue = personFacts.filter(fact => fact.fact_type.id === key);
    const configMatchValue = factsGrouped[key];
    let config = {
      label: configMatchValue[0].fact_type.label,
      fieldName: `fact_${key}`,
      isList: true,
      canSave:
        configMatchValue[0].fact_type.is_for_person && newValuesMethod(configPersonValue, configMatchValue).length,
      personValue: configPersonValue,
      matchValue: configMatchValue,
      displayMethod: displayMethod,
      newValuesMethod: newValuesMethod,
      modifyForSaveMethod: modifyForSaveMethod,
      cellClasses: 'multiline',
    };
    if (baseConsts.FACT_TYPES_PLACE_ID_WARNING.includes(configMatchValue[0].fact_type.id)) {
      config.personValue = configPersonValue.map(p => {
        return p && p.place_display_text && !p.place_id ? {...p, showIdWarning: true} : p;
      });
    }
    fields.push(config);
  });
  return fields;
}

export function getRelativeFullName(relative) {
  return relative.fullName;
}

export function getRelativesConfig(personRelatives, matchRelatives) {
  const relatives = {};
  matchRelatives.forEach(relative => {
    const relation = relative.relation.toLowerCase() || 'relative';
    relatives[relation] = relatives[relation] || {personValue: [], matchValue: []};
    relatives[relation].matchValue.push({
      ...relative,
      fullName: getRelativeNamesDisplay(relative),
      metaInfo: getRelativeMetaInfo(relative),
    });
  });
  personRelatives.forEach(relative => {
    const relation = relative.relation.toLowerCase() || 'relative';
    relatives[relation] = relatives[relation] || {personValue: [], matchValue: []};
    relatives[relation].personValue.push({
      ...relative,
      fullName: getRelativeNamesDisplay(relative),
      metaInfo: getRelativeMetaInfo(relative),
    });
  });
  const config = [];

  const relationNames = Object.keys(relatives);
  relationNames.sort((a, b) => {
    const order = [
      'spouse',
      'wife',
      'husband',
      'parent',
      'father',
      'mother',
      'sibling',
      'child',
      'son',
      'daughter',
      'relative',
    ];
    if (order.indexOf(a) === -1) {
      return 1;
    }
    if (order.indexOf(b) === -1) {
      return -1;
    }
    return order.indexOf(a) - order.indexOf(b);
  });
  relationNames.forEach(relation => {
    config.push({
      label: relation[0].toUpperCase() + relation.slice(1),
      isList: true,
      fieldName: relation,
      canSave: false,
      displayMethod: getRelativeFullName,
      personValue: relatives[relation].personValue.sort((a, b) => a.fullName.localeCompare(b.fullName)),
      matchValue: relatives[relation].matchValue.sort((a, b) => a.fullName.localeCompare(b.fullName)),
    });
  });
  return config;
}
function getCanSave(config, personValue, matchValue) {
  if (config.hasOwnProperty('canSave')) {
    return config.canSave;
  }
  if (config.newValuesMethod) {
    return config.newValuesMethod(personValue, matchValue).length > 0;
  }
  if (config.compareMethod) {
    return config.compareMethod(personValue, matchValue);
  }
  return false;
}

function splitNames(person, separateOtherNames) {
  const surnamesEn = person.surnames.filter(name => !isChineseText(name.value));
  const surnamesCn = person.surnames.filter(name => isChineseText(name.value));
  const surnamesLowercase = surnamesEn.filter(name => !!name.value).map(name => name.value.toLowerCase());
  const matchedSurnames = person.matched_surnames
    ? person.matched_surnames
        .filter(name => !isChineseText(name) && !surnamesLowercase.includes(name))
        .map(name => ({value: capitalize(name)}))
    : [];
  if (separateOtherNames) {
    const firstNamesEn = person.first_names.filter(name => name.type !== 'other_name' && !isChineseText(name.value));
    const firstNamesCn = person.first_names.filter(name => name.type !== 'other_name' && isChineseText(name.value));
    const otherNames = person.first_names.filter(name => name.type === 'other_name');
    return {
      first_names_en: firstNamesEn,
      first_names_cn: firstNamesCn,
      other_names: otherNames,
      surnames_en: surnamesEn,
      surnames_cn: surnamesCn,
      matched_surnames: matchedSurnames,
    };
  }
  const firstNamesEn = person.first_names.filter(name => !isChineseText(name.value));
  const firstNamesCn = person.first_names.filter(name => isChineseText(name.value));
  return {
    first_names_en: firstNamesEn,
    first_names_cn: firstNamesCn,
    other_names: [],
    surnames_en: surnamesEn,
    surnames_cn: surnamesCn,
    matched_surnames: matchedSurnames,
  };
}

export function createComparisonTable(person, matchPerson, matchMentionId) {
  const table = [];
  person = {...person, ...splitNames(person, false)};
  matchPerson = {...matchPerson, ...splitNames(matchPerson, true)};
  fieldsConfig.forEach(config => {
    const personValue = person[config.fieldName] || config.default;
    const matchValue = matchPerson[config.fieldName] || config.default;
    const personExtraDisplayValue = config.fieldName === 'surnames_en' ? person.matched_surnames : null;
    if (isEmpty(personValue) && isEmpty(matchValue) && isEmpty(personExtraDisplayValue)) {
      return;
    }
    const canSave = getCanSave(config, personValue, matchValue);
    const isNameField = ['first_names_en', 'surnames_en', 'first_names_cn', 'surnames_cn'].includes(config.fieldName);
    let itemConfig = {
      ...config,
      canSave: canSave,
      preselected: canSave && !isEmpty(matchValue) && (isEmpty(personValue) || isNameField),
      personValue,
      matchValue,
      personExtraDisplayValue,
    };
    if (
      ['birth_location', 'death_location'].includes(itemConfig.fieldName) &&
      personValue &&
      personValue.display_text &&
      !personValue.place_id
    ) {
      itemConfig.personValue.showIdWarning = true;
    }
    if (itemConfig.fieldName === 'residence_location' && personValue && personValue.length) {
      itemConfig.personValue = personValue.map(p => {
        return p && p.display_text && !p.place_id ? {...p, showIdWarning: true} : p;
      });
    }
    table.push(itemConfig);
  });
  if (matchPerson.other_fields && matchPerson.other_fields.length) {
    table.push(...getOtherFieldsConfig(matchPerson.other_fields));
  }
  table.push(...getFactsConfig(person.facts, matchPerson.facts, matchMentionId));
  table.push(...getRelativesConfig(person.relatives, matchPerson.relatives));
  return table;
}

export function createRelativesTable(personRelatives, matchRelatives) {
  const personParents = personRelatives.filter(r => r.relation_category === 'parents');
  const relativesTable = matchRelatives.filter(mRelative => {
    if (mRelative.is_potentially_living) {
      return;
    }
    if (mRelative.relation_category === 'parents') {
      return personParents.length >= 2
        ? false
        : !personParents.some(pRelative => mRelative.gender === pRelative.gender);
    }
    return !personRelatives.some(
      pRelative =>
        pRelative.full_name === mRelative.full_name && pRelative.relation_category === mRelative.relation_category
    );
  });
  return sortBy(relativesTable, 'relation_category');
}
