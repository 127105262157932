import { render, staticRenderFns } from "./HintsPage.vue?vue&type=template&id=13126bae&scoped=true&"
import script from "./HintsPage.vue?vue&type=script&lang=js&"
export * from "./HintsPage.vue?vue&type=script&lang=js&"
import style0 from "./HintsPage.vue?vue&type=style&index=0&id=13126bae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13126bae",
  null
  
)

export default component.exports