<template>
  <div class="record-type">
    <component :is="icon" :size="size"></component>
    <slot>
      <span class="avenir-font-offset-1">{{ typeName }}</span>
    </slot>
  </div>
</template>

<script>
import BookIcon from 'vue-material-design-icons/BookOpenOutline';

export default {
  props: {
    type: String,
    typeName: String,
    size: {type: Number, default: 22},
  },
  computed: {
    icon() {
      return BookIcon;
    },
  },
  name: 'RecordType',
};
</script>

<style lang="scss" scoped>
.record-type {
  display: flex;
  align-items: flex-start;
  color: $neutral-500;

  .material-design-icon {
    margin-right: 5px;
  }
}
</style>
