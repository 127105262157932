<template>
  <div class="subscription-banner">
    <div>
      <div class="heading-3 heading-6-mobile">Access improved text extraction models and translations!</div>
      <div class="link-text text-md">
        Upgrade to Detective Plan
        <chevron-right :size="16"></chevron-right>
      </div>
    </div>
    <div class="icon-wrapper">
      <translate-variant-icon :size="66"></translate-variant-icon>
    </div>
  </div>
</template>
<script>
import ChevronRight from 'vue-material-design-icons/ChevronRight';
import TranslateVariantIcon from 'vue-material-design-icons/TranslateVariant';

export default {
  name: 'translate-subscription-banner',
  components: {ChevronRight, TranslateVariantIcon},
};
</script>
<style lang="scss" scoped>
.subscription-banner {
  display: flex;
  column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 20px 24px;
  margin: 16px 24px 24px 24px;
  cursor: pointer;
  border-radius: 4px;
  background-image: linear-gradient($primary-200, $primary-600);
  .heading-3 {
    max-width: 575px;
    margin-bottom: 16px;
  }
  .link-text {
    display: flex;
    align-items: center;
    column-gap: 5px;
    white-space: nowrap;
  }
  .icon-wrapper {
    color: $primary-200;
  }
  &:hover {
    .link-text {
      text-decoration: underline;
    }
  }
}
@media only screen and (max-width: $breakpoint-tablet) {
  .subscription-banner {
    margin: 16px 16px 24px;
  }
}
</style>
