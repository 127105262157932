import OcrAreaConfirmModal from '@common/elements/layouts/area-selection/OcrAreaConfirmModal';

export const SUBSCRIBE_OPTION = 'subscribe';

export function getLangOptions(isStaff, isSubscribed) {
  let tesseract_info = isStaff ? ' (Tesseract)' : '';

  const defaultOptions = {
    en: 'English' + tesseract_info,
    cn: 'Chinese Autodetect' + tesseract_info,
    cn_vert: 'Chinese Vertical' + tesseract_info,
    cn_hor: 'Chinese Horizontal' + tesseract_info,
  };
  const externalOcr = {
    external_ocr: 'Google OCR',
    baidu_ocr: 'Baidu OCR',
  };
  if (isStaff) {
    return {...externalOcr, ...defaultOptions};
  }
  if (isSubscribed) {
    return {...externalOcr, cn: 'Basic OCR (free)'};
  }
  return defaultOptions;
}

export function gtLangOptionsAsArray(isStaff, isSubscribed, isTrialEligible, planName) {
  const options = Object.entries(getLangOptions(isStaff, isSubscribed)).map(([id, name]) => ({id, name}));
  if (!isSubscribed && !isStaff) {
    const tag = isTrialEligible ? 'Start a free trial' : `Upgrade to ${planName} Plan`;
    return [
      ...options,
      {id: `${SUBSCRIBE_OPTION}_google`, name: 'Google OCR', tag},
      {id: `${SUBSCRIBE_OPTION}_baidu`, name: 'Baidu OCR', tag},
    ];
  }
  return options;
}

export function openOcrAreaResultsModal(vm, imageWidth, imageHeight, rotate, url, areaSelected, renderScale, closeLabel) {
  vm.$modal.show(
    OcrAreaConfirmModal,
    {
      imageWidth: imageWidth,
      imageHeight: imageHeight,
      url: url,
      rotate: rotate,
      x1: areaSelected.x,
      x2: areaSelected.x2,
      y1: areaSelected.y,
      y2: areaSelected.y2,
      renderScale: renderScale,
      closeLabel: closeLabel,
    },
    {
      height: 'auto',
      scrollable: true,
      classes: 'clear_modal white_modal',
      class: 'over-gallery-overlay',
      name: 'ocr',
    }
  );
}
