<template>
  <span v-if="show" class="keycup" :style="style"><slot></slot></span>
</template>

<script>
export default {
  name: 'KeyCup',
  props: {
    size: {type: Number, default: 10},
    autoHide: {type: Boolean, default: true},
  },
  computed: {
    show() {
      return this.autoHide ? !this.isMobile : true;
    },
    style() {
      return {
        'font-size': `${this.size}px`,
        'line-height': `${this.size}px`,
        height: `${this.size + 1}px`,
      };
    },
    isMobile() {
      return this.$store.getters.windowWidthState < this.$breakpoints.tablet;
    },
  },
};
</script>

<style lang="scss" scoped>
.keycup {
  border: 1px solid #ffffff;
  border-bottom: 3px double #ffffff;
  padding: 4px 6px 2px 6px;
  border-radius: 2px;
  display: inline-block;

  &.dark {
    border: 1px solid $text-alternate-color;
    border-bottom: 3px double $text-alternate-color;
  }
}
</style>
