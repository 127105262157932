<template>
  <multiselect
    class="bordered ocr-type-select"
    :value="value"
    placeholder=""
    :searchable="false"
    :internal-search="false"
    :clearOnSelect="false"
    :options="options"
    track-by="id"
    label="name"
    :showLabels="false"
    @select="$emit('select', $event)"
  >
    <template v-slot:option="params">
      <span class="text-md">{{ params.option.name }}</span>
      <span v-if="params.option.tag" class="text-sm tag">{{ params.option.tag }}</span>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: Object,
    options: Array,
  },
  name: 'OcrTypeSelect',
  components: {Multiselect},
};
</script>

<style scoped lang="scss">
.ocr-type-select.multiselect {
  max-width: 400px;
  &::v-deep .multiselect__tags {
    cursor: pointer;
  }
  &::v-deep .multiselect__option {
    display: flex;
    align-items: center;
    column-gap: 12px;
    .tag {
      background: $primary-600;
      padding: 3px 8px;
      color: white;
      border-radius: 4px;
      font-weight: 800;
    }

    &.multiselect__option--highlight {
      .tag {
        background: white;
        color: $primary-600;
      }
    }
  }
}
</style>
