import {toQueryString} from '@common/utils/utils';

export function getBurialRecordAdminUrl(burialRecordId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/cemetery/burialrecord/${burialRecordId}/`;
}
export function getMentionsAdminUrl(personId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/sources/mention/?person_id=${personId}`;
}
export function getPersonAdminUrl(personId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/familytree/person/${personId}/change/`;
}
export function getWikiArticleAdminUrl(articleId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/wiki/wikiarticle/${articleId}`;
}
export function getClanPedigreeAdminUrl(claPedigreeId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/clan/clanpedigree/${claPedigreeId}`;
}

export function getHintAdminUrl(hintId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/hints/personhint/${hintId}`;
}
export function getSourceAdminUrl(sourceId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/sources/source/${sourceId}`;
}
export function getCollectionAdminUrl(collectionId) {
  return `${process.env.VUE_APP_BE_ADMIN_BASE}/sources/collection/${collectionId}`;
}
export function getOcrParserAdminUrl(sourceId, zupuId, page, pageId, cacheKey) {
  const pageParam = pageId ? {page_id: pageId} : {page};
  const bookId = sourceId ? {source_id: sourceId} : {zupu_id: zupuId};
  const queryParamsStr = toQueryString({ocr_cache_key: cacheKey, ...bookId, ...pageParam});
  return `https://admin.mychinaroots.com/data-indexing-tools/extractor/?${queryParamsStr}`;
}
