<template>
  <div>
    <div class="readable_content">
      <h1>Page Not Found!</h1>
      <p>Sorry, we couldn't find the page at this address.</p>
      <div class="bold">Suggestions</div>
      <ul>
        <li><router-link to="/">Visit our home page</router-link> for an overview of our services.</li>
        <li>Read <router-link :to="{name: 'testimonials'}">testimonials</router-link> from our customers.</li>
        <li>
          Search for records in our
          <router-link :to="searchRecordsRoute">Chinese Genealogical Records database</router-link>.
        </li>
        <li>Learn more about <router-link :to="{name: 'our-story'}">My China Roots and our story</router-link>.</li>
        <li><router-link :to="{name: 'contact'}">Contact us</router-link> via email or phone.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';

export default {
  metaInfo: {
    title: 'Page Not Found',
    meta: [
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  },
  computed: {
    searchRecordsRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}};
    },
  },
  name: 'page.not.found',
};
</script>

<style lang="scss" scoped></style>
