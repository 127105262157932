<template>
  <span class="material-design-icon workspace-premium-icon">
    <svg xmlns="http://www.w3.org/2000/svg" :height="size" viewBox="0 0 24 24" :width="size" fill="currentColor">
      <rect fill="none" height="24" width="24" />
      <path
        d="M9.68,13.69L12,11.93l2.31,1.76l-0.88-2.85L15.75,9h-2.84L12,6.19L11.09,9H8.25l2.31,1.84L9.68,13.69z M20,10 c0-4.42-3.58-8-8-8s-8,3.58-8,8c0,2.03,0.76,3.87,2,5.28V23l6-2l6,2v-7.72C19.24,13.87,20,12.03,20,10z M12,4c3.31,0,6,2.69,6,6 s-2.69,6-6,6s-6-2.69-6-6S8.69,4,12,4z M12,19l-4,1.02v-3.1C9.18,17.6,10.54,18,12,18s2.82-0.4,4-1.08v3.1L12,19z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 24,
    },
  },
  name: 'WorkspacePremiumIcon',
};
</script>

<style lang="scss" scoped></style>
