<template>
  <div class="comparison-table">
    <div class="table-legend table-row text-md">
      <div class="table-cell label selectable" @click="allFieldsCheckboxChange">
        <div class="input-wrapper">
          <input type="checkbox" id="all_fields_input" v-model="allFieldsSelected" v-if="saveMode" />
        </div>
        <div class="label-text">Type</div>
      </div>
      <div class="values">
        <div class="table-cell">New Data</div>
        <div class="table-cell">Your Tree</div>
      </div>
    </div>
    <div class="table-body table-row" v-for="item in displayTable" :class="getTableRowClass(item)">
      <div
        class="table-cell label text-md"
        :class="{selectable: saveMode && item.canSave}"
        @click="toggleCheckbox(item)"
      >
        <div class="input-wrapper">
          <input
            v-if="saveMode && item.canSave"
            type="checkbox"
            v-model="fieldsToSave"
            :id="item.fieldName"
            :value="item.fieldName"
          />
        </div>
        <div class="label-text">{{ item.label }}</div>
      </div>
      <div class="values">
        <compare-persons-table-cell
          :value="item.matchValue"
          :is-list="item.isList || false"
          :display-method="item.displayMethod"
          :comma-separated="item.commaSeparated"
          :class="item.cellClasses"
        ></compare-persons-table-cell>
        <compare-persons-table-cell
          :value="getPersonValue(item)"
          :new-value="getNewValues(item)"
          :is-selected="saveMode && fieldsToSave.includes(item.fieldName)"
          :is-list="item.isList || false"
          :display-method="item.displayMethod"
          :comma-separated="item.commaSeparated"
          :is-save-mode="saveMode"
          :class="item.cellClasses"
        ></compare-persons-table-cell>
      </div>
    </div>
  </div>
</template>

<script>
import ComparePersonsTableCell from '@/components/common/tree/ComparePersonsTableCell';

export default {
  props: {
    rows: Array,
    saveMode: {type: Boolean, default: false},
  },
  data() {
    return {
      fieldsToSave: [],
      allFieldsSelected: false,
      loading: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    saveTable() {
      return this.rows.filter(item => item.canSave);
    },
    displayTable() {
      return this.saveMode ? this.saveTable : this.rows;
    },
  },
  methods: {
    init() {
      this.fieldsToSave = this.saveTable.filter(item => item.preselected).map(item => item.fieldName);
      this.allFieldsSelected = this.saveTable.length === this.fieldsToSave.length;
    },
    getTableRowClass(item) {
      return this.saveMode && this.fieldsToSave.includes(item.fieldName) ? 'selected' : '';
    },
    toggleCheckbox(item) {
      if (!item.canSave) {
        return;
      }
      const index = this.fieldsToSave.indexOf(item.fieldName);
      if (index >= 0) {
        this.fieldsToSave.splice(index, 1);
      } else {
        this.fieldsToSave.push(item.fieldName);
      }
    },
    getPersonValue(item) {
      return !this.saveMode && item.personExtraDisplayValue
        ? [...item.personValue, ...item.personExtraDisplayValue]
        : item.personValue;
    },
    getNewValues(item) {
      return item.newValuesMethod ? item.newValuesMethod(item.personValue, item.matchValue) : item.matchValue;
    },
    getNewValuesForSave(item) {
      const newValues = this.getNewValues(item);
      if (item.modifyForSaveMethod) {
        return newValues.map(val => item.modifyForSaveMethod(val));
      }
      return newValues;
    },
    allFieldsCheckboxChange(event) {
      const newValue = !this.allFieldsSelected;
      this.allFieldsSelected = newValue;
      this.fieldsToSave = newValue ? this.saveTable.map(item => item.fieldName) : [];
    },
    getDataForSave() {
      const newData = {};
      for (let fieldName of this.fieldsToSave) {
        const item = this.saveTable.find(item => item.fieldName === fieldName);
        const newValue = this.getNewValuesForSave(item);
        if (fieldName === 'first_names_en' || fieldName === 'first_names_cn') {
          newData['first_names'] = newData['first_names'] || [];
          newData['first_names'].push(...newValue);
        } else if (fieldName === 'surnames_en' || fieldName === 'surnames_cn') {
          newData['surnames'] = newData['surnames'] || [];
          newData['surnames'].push(...newValue);
        } else if (fieldName.startsWith('fact_')) {
          newData['facts'] = newData['facts'] || [];
          newData['facts'].push(...newValue);
        } else {
          newData[fieldName] = newValue;
        }
      }
      return newData;
    },
  },
  components: {ComparePersonsTableCell},
  name: 'ComparePersonsTable',
};
</script>

<style lang="scss" scoped>
.comparison-table {
  border-radius: 4px;
  border: 1px solid $neutral-100;
  box-shadow: $slight-shadow;

  .table-legend {
    color: $neutral-500;
    font-weight: 800;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    box-shadow: $slight-shadow-bottom;
  }
  .table-row {
    display: flex;
    background: white;
    border-bottom: 1px solid $neutral-200;

    .values {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      flex-basis: 0;
    }

    &.selected {
      background: $neutral-50;
    }
  }
  .table-cell {
    padding: 16px 8px;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    &.label {
      max-width: 200px;
      .label-text {
        color: $neutral-500;
      }
      &.selectable {
        cursor: pointer;
      }
    }
    & .input-wrapper {
      display: flex;
      align-items: flex-start;
      padding: 5px 20px 0 12px;
      min-width: 13px;
    }
  }

  input[type='checkbox'] {
    margin: 0;
    cursor: pointer;
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}

@media only screen and (max-width: $breakpoint-mobile) {
  .comparison-table {
    .table-legend .label {
      max-width: 30px;
      .label-text {
        display: none;
      }
    }

    .table-body.table-row {
      flex-direction: column;
      .values {
        margin-top: 4px;
        margin-left: 30px;
      }
    }

    .table-row {
      padding: 12px 16px;
      .values {
        column-gap: 16px;
      }
    }

    .table-cell {
      padding: 0;
      .input-wrapper {
        padding: 3px 16px 0 0;
      }
    }
    .table-body.table-cell + .table-body.table-cell {
      margin-top: 4px;
    }
  }
}
</style>
