<template>
  <div class="person-icon-colored" :class="classes">
    <person-icon :gender="gender" :size="size"></person-icon>
  </div>
</template>

<script>
import PersonIcon from '@/base/icons/person/PersonIcon';

export default {
  props: {
    gender: String, // 'm' or 'f'
    colored: {type: Boolean, default: true},
    size: {type: Number, default: 68},
  },
  computed: {
    classes() {
      return this.colored ? [this.gender, 'colored'] : [this.gender];
    },
  },
  components: {PersonIcon},
};
</script>

<style lang="scss" scoped>
.person-icon-colored {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $neutral-gender-background-color;
  color: $neutral-500;

  &.colored.m {
    background-color: $male-background-color;
    color: $male-border-color;
  }
  &.colored.f {
    background-color: $female-background-color;
    color: $female-border-color;
  }
}
</style>
