<template>
  <div class="progress-bar">
    <div class="progress-value" :style="progressValueStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  computed: {
    progressValueStyle() {
      return {width: `${this.value || 0}%`};
    },
  },
  name: 'ProgressBar',
};
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  height: 3px;
  background: $neutral-200;
  .progress-value {
    background: $primary-200;
    width: 0%;
    height: 3px;
    transition: width 1s;
  }
}
</style>
