<template>
  <div class="tabs-bar">
    <div class="tabs-header">
      <div
        v-for="item in tabItems"
        :key="item.identifier"
        @click="activateTab(item.identifier)"
        class="tab"
        :class="{'is-active': item.identifier === tabActiveId, [item.identifier]: true}"
      >
        <component v-if="item.icon" :is="item.icon" :size="20"></component>
        <div>{{ getItemName(item) }}</div>
        <div v-if="item.reviewCount" class="review-count text-xs">{{ item.reviewCount }}</div>
      </div>
    </div>

    <div class="tabs-content stand-out">
      <slot name="common"></slot>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ProfileIcon from 'vue-material-design-icons/Account';
import RelationshipIcon from 'vue-material-design-icons/HumanMaleFemaleChild';
import AssetsIcon from 'vue-material-design-icons/Image';
import LightbulbIcon from 'vue-material-design-icons/Lightbulb';

export default {
  props: {
    startTab: String,
  },
  data() {
    return {
      tabItems: {},
      tabActiveId: '',
      tabsContentRef: 'tabs-content',
    };
  },
  methods: {
    register(tabItemInstance) {
      if (this.isTabItemComponent(tabItemInstance.$options)) {
        this.tabItems = {
          ...this.tabItems,
          [tabItemInstance.identifier]: this.tabItems[tabItemInstance.identifier] || tabItemInstance,
        };
        if (!this.tabActiveId && (!this.startTab || this.startTab === tabItemInstance.identifier)) {
          this.activateTab(tabItemInstance.identifier);
        }
      }
    },
    isTabItemComponent(options) {
      return options && options.name === 'TabsBlockItem';
    },
    activateTab(identifier) {
      if (this.tabActiveId === identifier) {
        return;
      }
      const fromTabId = this.tabActiveId;
      this.tabActiveId = identifier;
      for (let tab of Object.values(this.tabItems)) {
        tab.setIsActive(tab.identifier === this.tabActiveId);
        if (fromTabId && tab.identifier === fromTabId) {
          tab.deactivate();
        }
      }
      this.$emit('activate-tab', {tabId: this.tabActiveId, fromTabId});
    },
    getItemName(item) {
      return item.name;
    },
    fullDeactivate() {
      for (let tab of Object.values(this.tabItems)) {
        if (this.tabActiveId && tab.identifier === this.tabActiveId) {
          tab.deactivate();
        }
      }
    },
  },
  components: {
    ProfileIcon,
    AssetsIcon,
    LightbulbIcon,
    RelationshipIcon,
  },
  name: 'TabsBar',
};
</script>

<style lang="scss" scoped>
.tabs-bar {
  .tabs-header {
    display: flex;
    overflow-x: auto;
    font-size: $text-size;
    line-height: $line-height;
    background: $background-light;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
      display: none;
      height: 0px;
      width: 0px;
      background: transparent;
    }

    &::v-deep {
      .tab {
        cursor: pointer;
        padding: 10px 14px;
        white-space: nowrap;
        color: $neutral-500;
        display: flex;
        align-items: center;

        .material-design-icon {
          margin-right: 8px;
        }

        .review-count {
          border-radius: 100%;
          background: $primary-400;
          color: $white;
          width: 20px;
          height: 20px;
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.is-active {
          font-weight: 800;
          color: $text-color;
          box-shadow: inset 0 -2px 0 $primary-400;
        }
      }
    }
  }

  &.tabs-content-reverse {
    .tabs-content {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .tabs-header::v-deep {
      .tab {
        padding: 10px 10px;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        .material-design-icon {
          display: none;
        }
      }
    }
  }
}
</style>
