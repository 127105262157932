<template>
  <modal :name="modalName" classes="clear_modal white_modal" :scrollable="true" height="auto" @before-open="resetData">
    <div class="save-data-modal-content with-sticky-buttons">
      <div class="heading">
        <div class="text-lg bold" v-if="isRelativesTableShown">Select relationships to save</div>
        <div class="text-lg bold" v-else>Select details to save</div>
        <close-icon class="close-icon" @click="closeModal"></close-icon>
      </div>

      <progress-bar :value="progressValue" v-if="progressValue"></progress-bar>

      <div class="body">
        <div class="top-data">
          <div class="person-data-container">
            <div class="profile-picture-container">
              <img
                :src="person.profile_picture.attachment"
                class="profile-picture"
                v-if="person.profile_picture && person.profile_picture.attachment"
              />
              <person-icon-colored v-else :gender="person.gender"></person-icon-colored>
            </div>
            <div class="name-container">
              <div class="bold text-lg text-lg-mobile">{{ person.full_name }}</div>
              <div class="living-status text-sm text-sm-mobile">{{ livingStatus }}</div>
            </div>
          </div>
          <div class="text-sm see-relatives-link" v-if="isRelativesTableShown">
            <router-link :to="personRelativesRoute" target="_blank"
              >See {{ primaryFirstName }}’s Relatives <link-icon class="link-icon" :size="18"></link-icon
            ></router-link>
          </div>
        </div>

        <stand-out-block class="warning text-md" v-if="showRelativesWarningBlock">
          <div class="info-icon-wrapper">
            <info-icon :size="20"></info-icon>
          </div>
          <div class="text">
            <div><b>Notice: </b>Relationships selected below will add new persons to your family tree.</div>
            <div>
              To save information to persons already in your tree, leave the relationship unchecked, and you should
              receive a separate record for that person later.
            </div>
            <div class="action"><a @click="hideRelativesWarning">I understand, don't show this again</a></div>
          </div>
        </stand-out-block>

        <compare-relatives-table
          v-show="isRelativesTableShown"
          :relatives-table="relativesTable"
          :person-relatives="personRelatives"
          ref="relatives"
        ></compare-relatives-table>
        <compare-persons-table
          :rows="comparisonTable"
          ref="table"
          :save-mode="true"
          v-show="!isRelativesTableShown"
        ></compare-persons-table>
      </div>

      <div class="action-buttons right-align">
        <mcr-button class="white" v-if="showCancel" @click="closeModal">Cancel</mcr-button>
        <mcr-button class="white" v-if="showBack" @click="goBack"
          ><arrow-left-icon :size="18" /><span>Back</span></mcr-button
        >
        <mcr-button class="save" v-if="showSave" @click="onSave">Save Details</mcr-button>
        <mcr-button class="save" v-else-if="showNext" @click="goNext">Next</mcr-button>
        <mcr-button class="save" v-else-if="showAddPersons" @click="onSave">Add Persons</mcr-button>
      </div>
    </div>
  </modal>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import ProgressBar from '@common/elements/layouts/ProgressBar';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getLifetimeYears} from '@common/utils/utils';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft';
import CloseIcon from 'vue-material-design-icons/Close';
import InfoIcon from 'vue-material-design-icons/Information';
import LinkIcon from 'vue-material-design-icons/OpenInNew';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import ComparePersonsTable from '@/components/common/tree/ComparePersonsTable';
import CompareRelativesTable from '@/components/common/tree/CompareRelativesTable';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    person: Object,
    familyTreeId: Number,
    comparisonTable: Array,
    relativesTable: Array,
    personRelatives: Array,
  },
  data() {
    return {
      modalName: 'save-data-modal',
      isRelativesTableShown: !this.comparisonTable.length && this.relativesTable.length,
      showRelativesWarning: true,
    };
  },
  computed: {
    primaryFirstName() {
      const names = this.person.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    showSave() {
      return this.comparisonTable.length && !this.relativesTable.length;
    },
    showNext() {
      return this.comparisonTable.length && this.relativesTable.length && !this.isRelativesTableShown;
    },
    showAddPersons() {
      return this.isRelativesTableShown;
    },
    showCancel() {
      return this.comparisonTable.length && !this.isRelativesTableShown;
    },
    showBack() {
      return this.comparisonTable.length && this.isRelativesTableShown;
    },
    progressValue() {
      if (this.relativesTable.length && this.comparisonTable.length) {
        return this.isRelativesTableShown ? 100 : 50;
      }
      return 0;
    },
    showRelativesWarningBlock() {
      return (
        this.isRelativesTableShown &&
        this.showRelativesWarning &&
        !this.$store.getters.tutorialsShownState.HINTS_RELATIVES
      );
    },
    personRelativesRoute() {
      return {
        name: 'familytree-profile-details',
        params: {id: this.familyTreeId, personId: this.person.object_id},
        query: {tab: 'relationships'},
      };
    },
  },
  methods: {
    resetData() {
      this.isRelativesTableShown = !this.comparisonTable.length && this.relativesTable.length;
      this.$store.dispatch('getTutorialsShownAction');
    },
    closeModal() {
      AnalyticsMainHandler.trackHintSaveDataClickBackEvent();
      this.$modal.hide(this.modalName);
    },
    onSave() {
      this.$modal.hide(this.modalName);
      const personData = this.$refs.table.getDataForSave();
      const relativesData = this.$refs.relatives.getDataForSave();
      this.$emit('save', {personData, relativesData});
    },
    goNext() {
      this.isRelativesTableShown = true;
    },
    goBack() {
      this.isRelativesTableShown = false;
    },
    hideRelativesWarning() {
      this.showRelativesWarning = false;
      this.$store.dispatch('updateTutorialsShownAction', {tutorial_id: 'HINTS_RELATIVES'});
    },
  },
  components: {
    InfoIcon,
    StandOutBlock,
    CompareRelativesTable,
    PersonIconColored,
    ComparePersonsTable,
    CloseIcon,
    McrButton,
    ProgressBar,
    ArrowLeftIcon,
    LinkIcon,
  },
  name: 'SaveDataModal',
};
</script>

<style lang="scss" scoped>
.save-data-modal-content {
  width: $reading-max-width;
  .top-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  .person-data-container {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }

  .see-relatives-link {
    a {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
  .living-status {
    color: $neutral-500;
  }
  .profile-picture-container {
    border-radius: 50%;
    overflow: hidden;
    width: 44px;
    height: 44px;
    flex-shrink: 0;

    &::v-deep svg {
      width: 30px;
      height: 30px;
    }
  }

  .comparison-table,
  .compare-relatives-table {
    margin: 0 24px 16px;
  }
  .warning {
    margin: 0 24px 24px;
  }

  .warning {
    box-shadow: $slight-shadow-bottom;
    background: white;
    color: $neutral-500;
    padding: 12px 16px;
    display: flex;
    column-gap: 12px;

    .info-icon-wrapper {
      flex-shrink: 0;
      border-radius: 50%;
      background: $neutral-100;
      color: $neutral-600;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text {
      div + div {
        margin-top: 8px;
      }
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    .comparison-table,
    .compare-relatives-table,
    .warning {
      margin: 0 0 16px;
    }

    .top-data {
      flex-direction: column;
      row-gap: 20px;
      padding-bottom: 20px;
      align-items: flex-start;
    }
  }
}
</style>
