<script>
import UnknownPersonIcon from 'vue-material-design-icons/CircleOutline';
import MalePersonIcon from 'vue-material-design-icons/FaceMan';
import FemalePersonIcon from 'vue-material-design-icons/FaceWoman';

export default {
  functional: true,
  render: function (createElement, context) {
    const iconsByGender = {
      m: MalePersonIcon,
      f: FemalePersonIcon,
    };

    return createElement(iconsByGender[context.props.gender] || UnknownPersonIcon, context.data, context.children);
  },
};
</script>
